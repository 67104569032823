<template>
  <div class="row">
    <div class="col-12">
      <div class="card concard card-shadow">
        <Header></Header>

        <div class="text-start mn-profile">
          <span class="text-root mini-h"> ข้อมูลประชากร </span>
          <i @click="registerEmp" class="bi bi-person-plus icon"></i>
        </div>
        <div class="card-body mt-2 white">
          <div class="p-10">
            <tabs v-model="active">
              <tab>ข้อมูลบ้าน</tab>
              <tab>สมาชิกในบ้าน</tab>
              <tab>ค่าธรรมเนียมขยะ</tab>
            </tabs>
          </div>
        </div>

        <tab-panels v-model="active">
          <tab-panel>
            <Home></Home>
          </tab-panel>
          <tab-panel>
            <Member></Member>
          </tab-panel>
          <tab-panel> <Garbage></Garbage> </tab-panel>
        </tab-panels>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, inject, ref } from "vue";
import Home from "@/components/Home";
import Garbage from "@/components/Garbage";
import Member from "@/components/Member";
import liff from "@line/liff";
export default defineComponent({
  name: "Dashboard_home",
  components: {
    Home,
    Garbage,
    Member,
  },
  setup() {
    const store = inject("store");
    const router = inject("router");
    const active = ref(0);

    const registerEmp = () => {
      liff.ready.then(() => {
        liff.openWindow({
          url: `line://app/${process.env.VUE_APP_LIFF_REGISTER_EMP}`,
          external: false,
        });
      });
    };

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    return {
      active,
      registerEmp,
    };
  },
});
</script>



<style scoped>
.bi-person-plus {
  font-size: 20px;
  color: var(--color);
  float: left;
  margin-top: 160px;
}

.card-body {
  padding: 0;
}

.title {
  background-color: white;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 15px 10px;
}
.title-text {
  font-size: 12px;
  margin-right: 5px;
}
.icon-logo {
  height: 27px;
  background-color: white;
  margin-left: 10px;
  border-radius: 50%;
  margin-top: -10px;
}
.body-text {
  height: 16rem;
  position: relative;
}
.hide-scrollbar {
  position: relative;
  height: 100%;
  overflow-y: hidden;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.scrollbar {
  height: 0.25rem;
  border-radius: 0.0625rem;
  position: absolute;
  bottom: 0.3125rem;
  left: calc(50% - 0.75rem);
  width: 1.5rem;
  background: var(--color);
}
.scrollbar-st {
  background: #ee4d2d;
  width: 10.801px;
  transform: translateX(0px);
}
.appeal-title {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 0.125rem;
}

.appeal-item {
  width: 33.33%;
  padding: 0 0.0625rem;
  text-align: center;
  height: 7rem;
}
.appeal-image {
  position: relative;
}
.appeal-image-logo {
  vertical-align: bottom;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border: 1.3px solid #dee2e6;
  border-radius: 15px;
}
.history-image {
  position: relative;
}
.history-image-logo {
  vertical-align: bottom;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border: 1.3px solid #dee2e6;
  border-radius: 15px;
}
.appeal-text {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 2.625rem;
  font-size: 12px;
  line-height: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  word-wrap: break-word;
  overflow: hidden;
  white-space: normal;
  height: 30px;
}
.history-header {
  padding: 10px;
  display: flex;
}
.history-title {
  width: 50%;
  margin-left: 5px;
  font-size: 13px;
  font-weight: 500;
}
.history-status {
  width: 50%;
  font-size: 12px;
  text-align: end;
}
.history-body {
  padding: 10px;
  display: flex;
  position: relative;
}
.history-detail {
  margin-left: 10px;
}
.history-footer {
  font-size: 12px;
  padding: 10px;
  margin-left: 5px;
}
.history-botton {
  position: absolute;
  right: 10px;
  bottom: 0;
}
.see-more {
  background-color: var(--color) !important;
  border-color: var(--color) !important;
  color: white;
}
.history-empty {
  position: relative;
  height: 157.5px;
}
.history-empty-text {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px 0 0 -40px;
  opacity: 0.8;
}
</style>
