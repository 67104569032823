<template>
  <div>
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>

    <div class="col-12">
      <div class="card concard card-shadow">
        <Header></Header>

        <div class="text-start mn-profile">
          <span class="text-root mini-h"> ชำระค่าธรรมเนียมขยะ </span>
        </div>
        <div class="card-body">
          <div style="padding: 10px 20px" class="mt-2 white">
            <div class="body-text mb-2">
              <span class="text-root" style="font-size: 16px"
                >ช่องทางการชำระเงิน</span
              >
              <div
                class="card mt-3"
                style="
                  border: 10px solid #00a3e4 !important;
                  position: relative;
                  border-radius: 10px;
                "
              >
                <img
                  :src="ktb"
                  style="width: 100px; margin-bottom: -1px"
                  alt=""
                />
                <span
                  style="
                    position: absolute;
                    left: 110px;
                    font-size: 16px;
                    top: 5px;
                  "
                  >ธนาคารกรุงไทย</span
                >
                <span
                  style="
                    position: absolute;
                    left: 110px;
                    font-size: 16px;
                    top: 35px;
                  "
                  >ชื่อบัญชี เทศบาลตำบลรัษฎา</span
                >
                <span
                  style="
                    position: absolute;
                    left: 110px;
                    font-size: 16px;
                    top: 65px;
                  "
                  >เลขบัญชี 377-1-00995-2
                </span>
              </div>
            </div>
          </div>
          <Form
            @submit="onSubmitPay"
            :validation-schema="pay"
            id="kt_account_profile_details_form"
            ref="formContainer"
            novalidate="novalidate"
          >
            <div class="card-body mb-47 mt-2 white">
              <!--begin::Card body-->
              <div class="card-body">
                <!--begin::Input group-->
                <div class="row" style="padding: 10px">
                  <!--begin::Label-->
                  <label class="col-lg-4 col-form-label fw-bold fs-6"
                    >เดือน</label
                  >
                  <!--end::Label-->

                  <!--begin::Col-->
                  <div class="col-lg-8 fv-row">
                    {{ month(data_bill.month) }} {{ year(data_bill.year) }}
                  </div>
                  <!--end::Col-->
                </div>

                <!--begin::Input group-->
                <div class="row" style="padding: 10px">
                  <!--begin::Label-->
                  <label class="col-lg-4 col-form-label required fw-bold fs-6"
                    >ยอดชำระ</label
                  >
                  <!--end::Label-->

                  <!--begin::Col-->
                  <div class="col-lg-8 fv-row">
                    <Field
                      type="number"
                      name="payout"
                      class="form-control form-control-solid"
                      placeholder="ยอดชำระ"
                      v-model="data_bill.payout"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="payout" />
                      </div>
                    </div>
                  </div>
                  <!--end::Col-->
                </div>

                <div class="row" style="padding: 10px">
                  <!--begin::Label-->
                  <label class="col-lg-4 col-form-label required fw-bold fs-6"
                    >วันที่โอน</label
                  >
                  <!--end::Label-->

                  <!--begin::Col-->
                  <div class="col-lg-8 fv-row">
                    <Datepicker
                      v-model="day_pay"
                      :format-locale="th"
                      cancelText="ยกเลิก"
                      selectText="เลือก"
                      locale="th"
                      placeholder="เลือกวัน"
                      :enableTimePicker="false"
                    ></Datepicker>
                    <Field type="hidden" name="day_pay" v-model="day_pay" />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="day_pay" />
                      </div>
                    </div>
                  </div>
                  <!--end::Col-->
                </div>

                <div class="row" style="padding: 10px">
                  <!--begin::Label-->
                  <label class="col-lg-4 col-form-label required fw-bold fs-6"
                    >เวลาที่โอน</label
                  >
                  <!--end::Label-->

                  <!--begin::Col-->
                  <div class="col-lg-8 fv-row">
                    <Datepicker
                      v-model="time_pay"
                      :format-locale="th"
                      cancelText="ยกเลิก"
                      selectText="เลือก"
                      locale="th"
                      placeholder="เลือกเวลา"
                      timePicker
                    ></Datepicker>
                    <Field type="hidden" name="time_pay" v-model="time_pay" />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="time_pay" />
                      </div>
                    </div>
                  </div>
                  <!--end::Col-->
                </div>

                <!--begin::Input group-->
                <div class="row mb-3" style="padding: 10px">
                  <!--begin::Label-->
                  <label class="col-lg-4 col-form-label required fw-bold fs-6"
                    >แนบรูป สลิปการโอนเงิน</label
                  >
                  <!--end::Label-->

                  <!--begin::Col-->
                  <div class="col-lg-8 fv-row">
                    <Field type="hidden" name="imageUrl" v-model="imageUrl" />
                    <input
                      type="file"
                      id="getFile"
                      accept="image/jpg, image/jpeg, image/png"
                      @input="pickFile"
                      style="display: none"
                    />

                    <div
                      v-if="imageUrl"
                      class="imagePreviewWrapper"
                      style="padding: 15px 0px"
                    >
                      <div class="row">
                        <div style="position: relative" class="col-12">
                          <div class="xx" @click="deleteImage">
                            <span>x</span>
                          </div>
                          <img class="images" :src="imageUrl" />
                        </div>
                      </div>
                    </div>

                    <div v-else class="d-flex justify-center align-center">
                      <div style="width: 100%; padding: 0px 5px">
                        <button
                          type="button"
                          @click="selectImage"
                          class="form-control btn btn-outline-secondary"
                        >
                          แนบรูป<img
                            style="width: 100px"
                            src="@/assets/icon/bill.png"
                            class="icon-ima"
                          />
                        </button>
                      </div>
                    </div>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="imageUrl" />
                      </div>
                    </div>
                  </div>
                  <!--end::Col-->
                </div>
              </div>
              <!--end::Card body-->
            </div>
            <div class="card-footer white">
              <div class="d-flex justify-center align-center">
                <div style="width: 100%; padding: 0px 5px">
                  <button type="button" @click="back" class="button btn-back">
                    ย้อนกลับ
                  </button>
                </div>
                <div style="width: 100%; padding: 0px 5px">
                  <button type="submit" style="width: 100%" class="button">
                    ชำระค่าธรรมเนียม
                  </button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ktb from "@/assets/icon/ktb.png";
import { ErrorMessage, Field, Form } from "vee-validate";
import { defineComponent, onMounted, inject, ref } from "vue";
import useSweetalert from "@/service/sweetalert2";
import useRegisters from "@/service/api/register";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { th } from "date-fns/locale";
import * as Yup from "yup";
import moment from "moment";

export default defineComponent({
  name: "Profire",
  props: {
    bill: {
      type: [Object],
    },
  },
  components: {
    ErrorMessage,
    Field,
    Form,
    Datepicker,
  },
  setup(props) {
    const store = inject("store");
    const router = inject("router");
    const { Sconfirm, SconfirmNc } = useSweetalert();
    const { garbagePay } = useRegisters();

    const data_bill = ref(props.bill);
    const day_pay = ref("");
    const time_pay = ref("");
    const imageUrl = ref("");

    let isLoading = ref(false);
    let color = ref(process.env.VUE_APP_COLOR);

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const pay = Yup.object().shape({
      payout: Yup.string().required("กรุณากรอกยอด"),
      day_pay: Yup.date()
        .required("กรุณาเลือกวันที่โอน")
        .typeError("กรุณาเลือกวันที่โอน"),
      time_pay: Yup.object().required("กรุณาเลือกเวลาที่โอน").nullable(),
      imageUrl: Yup.string().required("กรุณาแนบสลิปการโอน"),
    });

    const onSubmitPay = (values) => {
      Sconfirm("ยืนยัน การชำระค่าธรรมเนียมขยะ", "question")
        .then(async (result) => {
          if (result.isConfirmed) {
              isLoading.value = true
            values.id_line = store.getters.getAccessToken.accesstoken;
            values.id_bill = props.bill.id;
            await garbagePay(values).then((result) => {
              if (result.data.status === "success") {
                  isLoading.value = false
                SconfirmNc("ชำระค่าธรรมเนียมขยะสำเร็จ", "success", "ตกลง").then(
                  () => {
                    router.push({ name: "Garbage_Home" });
                  }
                );
              }
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            SconfirmNc("เกิดข้อผิดผาด กรุณาลองใหม่อีกครั้ง", "error", "ตกลง");
          }
        });
    };

    function month(date) {
      var march = moment().month(date - 1).locale("th");

      return march.format("MMMM");
    }

    function year(date) {
      var year = parseInt(date) + 543;

      return year;
    }

    const back = () => {
      router.push({ name: "Garbage_Home" });
    };

    const selectImage = () => {
      document.getElementById("getFile").click();
    };
    const deleteImage = () => {
      imageUrl.value = "";
    };

    const pickFile = async (e) => {
      const files = e.target.files;

      if (files) {
        imageUrl.value = await reduce_image_file_size(
          await image_to_base64(files[0])
        );
      }
    };

    async function reduce_image_file_size(
      base64Str,
      MAX_WIDTH = 1200,
      MAX_HEIGHT = 1200
    ) {
      let resized_base64 = await new Promise((resolve) => {
        let img = new Image();

        img.src = base64Str;
        img.onload = () => {
          let canvas = document.createElement("canvas");
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > MAX_WIDTH) {
              height *= MAX_WIDTH / width;
              width = MAX_WIDTH;
            }
          } else {
            if (height > MAX_HEIGHT) {
              width *= MAX_HEIGHT / height;
              height = MAX_HEIGHT;
            }
          }
          canvas.width = width;
          canvas.height = height;
          let ctx = canvas.getContext("2d");

          ctx.drawImage(img, 0, 0, width, height);

          resolve(canvas.toDataURL("image/jpeg")); // this will return base64 image results after resize
        };
      });
      return resized_base64;
    }

    async function image_to_base64(file) {
      let result_base64 = await new Promise((resolve) => {
        let fileReader = new FileReader();

        fileReader.onload = () => resolve(fileReader.result);
        fileReader.onerror = (error) => {
          console.log(error);
          alert("An Error occurred please try again, File might be corrupt");
        };
        fileReader.readAsDataURL(file);
      });

      return result_base64;
    }

    return {
      month,
      year,
      back,
      ktb,
      pay,
      onSubmitPay,
      data_bill,
      day_pay,
      time_pay,
      th,
      selectImage,
      deleteImage,
      pickFile,
      imageUrl,
      isLoading,
      color
    };
  },
});
</script>
<style scoped>
.card-body {
  padding: 0;
}

.title {
  background-color: white;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 15px 10px;
}
.title-text {
  font-size: 12px;
  margin-right: 5px;
}
.icon-logo {
  height: 27px;
  background-color: white;
  margin-left: 10px;
  border-radius: 50%;
  margin-top: -10px;
}
.body-text {
  position: relative;
}
.hide-scrollbar {
  position: relative;
  height: 100%;
  overflow-y: hidden;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.scrollbar {
  height: 0.25rem;
  border-radius: 0.0625rem;
  position: absolute;
  bottom: 0.3125rem;
  left: calc(50% - 0.75rem);
  width: 1.5rem;
  background: var(--color);
}
.scrollbar-st {
  background: #ee4d2d;
  width: 10.801px;
  transform: translateX(0px);
}
.appeal-title {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 0.125rem;
}

.appeal-item {
  width: 33.33%;
  padding: 0 0.0625rem;
  text-align: center;
  height: 7rem;
}
.appeal-image {
  position: relative;
}
.appeal-image-logo {
  vertical-align: bottom;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border: 1.3px solid #dee2e6;
  border-radius: 15px;
}
.history-image {
  position: relative;
}
.history-image-logo {
  vertical-align: bottom;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border: 1.3px solid #dee2e6;
  border-radius: 15px;
}
.appeal-text {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 2.625rem;
  font-size: 12px;
  line-height: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  word-wrap: break-word;
  overflow: hidden;
  white-space: normal;
  height: 30px;
}
.history-header {
  padding: 10px;
  display: flex;
}
.history-title {
  width: 50%;
  margin-left: 5px;
  font-size: 13px;
  font-weight: 500;
}
.history-status {
  width: 50%;
  font-size: 12px;
  text-align: end;
}
.history-body {
  padding: 10px;
  display: flex;
  position: relative;
}
.history-detail {
  margin-left: 10px;
}
.history-footer {
  font-size: 12px;
  padding: 10px;
  margin-left: 5px;
}
.history-botton {
  position: absolute;
  right: 10px;
  bottom: 0;
}
.see-more {
  background-color: var(--color) !important;
  border-color: var(--color) !important;
  color: white;
}
.history-empty {
  position: relative;
  height: 157.5px;
}
.history-empty-text {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px 0 0 -40px;
  opacity: 0.8;
}

.images {
  width: 100%;
}
.xx {
  position: absolute;
  background-color: #bd313e;
  color: white;
  right: 12px;
  padding: 6px;
}
</style>
