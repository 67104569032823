<template>
  <div class="vld-parent">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>
    <template v-if="bills.length > 0">
      <template v-for="bill in bills" :key="bill">
        <div style="padding: 10px 20px" class="mt-2 white">
          <div class="body-text">
            <router-link
              :to="{
                name: 'Garbage_Show',
                params: { id: bill.id },
              }"
            >
              <div class="p-item">
                <div class="image">
                  <img :src="bill_img" class="appeal-image-logo" alt="" />
                </div>
                <div class="text" style="padding: 6px" v-if="bill.status === 'ค้างชำระ'">
                  เดือน {{ month(bill.month) }} {{ year(bill.year) }}<br />
                  ยอดค้างชำระ <span class="rr">{{ bill.payout }} บาท</span>
                  <span class="rr1">{{ bill.status }} </span>
                </div>
                <div class="text" style="padding: 6px" v-else>
                  เดือน {{ month(bill.month) }}  {{ year(bill.year) }}<br />
                   <span class="rr wait">{{ bill.status }} </span>
                  
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </template>
    </template>
    <template v-else>
      <div class="card-body mt-3 white">
        <div class="history-empty">
          <div class="history-empty-text">ยังไม่มีข้อมูล</div>
        </div>
      </div>
    </template>

    <div class="text-start mn-profile mt-3">
      <span class="text-root mini-h"> ประวัติการชำระ </span>
    </div>

    <template v-if="bill_succ_s.length > 0">
      <template v-for="bill_succ in bill_succ_s" :key="bill_succ">
        <div style="padding: 10px 20px" class="mt-2 white">
          <div class="body-text">
            <router-link
              :to="{
                name: 'Garbage_Show',
                params: { id: bill_succ.id },
              }"
            >
              <div class="p-item">
                <div class="image">
                  <img :src="bill_img" class="appeal-image-logo" alt="" />
                </div>
                <div class="text" style="padding: 6px">
                  เดือน {{ month(bill_succ.month) }} {{ year(bill_succ.year)
                  }}<br />
                  <span class="orange" v-if="bill_succ.status === 'รอตรวจสอบ'"
                    >{{ bill_succ.status }}
                  </span>
                  <span class="green" v-if="bill_succ.status === 'เสร็จสิ้น'"
                    >{{ bill_succ.status }}
                  </span>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </template>
    </template>
    <template v-else>
      <div class="card-body mt-3 white">
        <div class="history-empty">
          <div class="history-empty-text">ยังไม่มีข้อมูล</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, inject } from "vue";
import useRegisters from "@/service/api/register";
import bill_img from "@/assets/icon/bill.png";
import moment from "moment";
export default defineComponent({
  name: "Profire",
  setup() {
    const { getBills } = useRegisters();
    const bills = ref("");
    const bill_succ_s = ref("");
    const store = inject("store");
    const router = inject("router");

    let isLoading = ref(true);
    let color = ref(process.env.VUE_APP_COLOR);

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }

      getBills(store.getters.getAccessToken.accesstoken).then((res) => {
        bills.value = res.data.data;
        bill_succ_s.value = res.data.data1;
        isLoading.value = false;
      });
    });

    function month(date) {


      var march = moment().month(date - 1).locale("th");

      return march.format("MMMM");
    }

    function year(date) {
      var year = parseInt(date) + 543;

      return year;
    }

    return { bills, bill_succ_s, isLoading, color, month, year, bill_img };
  },
});
</script>
<style scoped>
.rr {
  position: absolute;
  right: 20px;
  color: red;
  font-size: 18px;
  top: 10px;
}
.wait{
  color: orange;
}
.orange {
  position: absolute;
  right: 20px;
  color: orange;
  font-size: 18px;
  top: 10px;
}
.green {
  position: absolute;
  right: 20px;
  color: green;
  font-size: 18px;
  top: 10px;
}
.rr1 {
  position: absolute;
  right: 20px;
  color: red;
  font-size: 12px;
  bottom: 10px;
}
.icon-add {
  float: none;
  margin-left: 5px;
}
.card-body {
  padding: 0;
}
.title {
  background-color: white;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 15px 10px;
}
.title-text {
  font-size: 12px;
  margin-right: 5px;
}
.icon-logo {
  height: 27px;
  background-color: white;
  margin-left: 10px;
  border-radius: 50%;
  margin-top: -10px;
}
.ml-2 {
  margin-left: 2px;
}

.appeal-title {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 0.125rem;
}
.ml {
  padding: 10px;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.p-item {
  display: flex;
  min-height: 5rem;
  border-bottom: 1.3px solid #dee2e6;
  position: relative;
}
.appeal-image {
  position: relative;
}
.appeal-image-logo {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border: 1.3px solid #dee2e6;
  border-radius: 15px;
  padding: 5px;
}
.history-empty {
  position: relative;
  height: 90px;
}
.history-empty-text {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px 0 0 -40px;
  opacity: 0.8;
}
</style>
