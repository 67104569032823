<template>
  <div class="row">
    <loading
      v-model:active="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :color="color"
      loader="dots"
    />
    <div class="col-12 f13">
      <div class="card concard card-shadow">
        <div class="card-header white text-center">
          <img src="@/assets/logo.png" style="height: 100px" alt="..." />
          <div class="t1" style="margin: 5px">ข้อมูลสมาชิกในบ้าน</div>
        </div>
        <Form
          @submit="onSubmitRegister"
          :validation-schema="register"
          id="kt_account_profile_details_form"
          ref="formContainer"
          novalidate="novalidate"
        >
          <div class="card-body mb-47 mt-2 white">
            <!--begin::Card body-->
            <div class="card-body">
              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >เพศ</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <div class="row mt-2">
                    <div class="col-6">
                      <label
                        class="form-check form-check-custom form-check-solid me-9"
                      >
                        <Field
                          type="radio"
                          name="gender"
                          class="form-check-input"
                          value="ชาย"
                        />
                        <span
                          class="form-check-label gender"
                          for="kt_roles_select_all"
                          >ชาย <img :src="iconMale" class="icon-gender" alt=""
                        /></span>
                      </label>
                    </div>
                    <div class="col-6">
                      <label
                        class="form-check form-check-custom form-check-solid me-9"
                      >
                        <Field
                          type="radio"
                          name="gender"
                          class="form-check-input"
                          value="หญิง"
                        />

                        <span
                          class="form-check-label gender"
                          for="kt_roles_select_all"
                          >หญิง
                          <img :src="iconFemale" class="icon-gender" alt=""
                        /></span>
                      </label>
                    </div>
                  </div>
                  <div class="fv-plugins-message-container mt-3">
                    <div class="fv-help-block">
                      <ErrorMessage name="gender" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >อายุ</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    type="number"
                    name="age"
                    class="form-control form-control-solid"
                    placeholder="อายุ"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="age" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <div class="row mb-6">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >กลุ่มเปราะบาง</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    name="type"
                    class="form-control form-control-solid"
                    as="select"
                    v-model="type"
                  >
                    <option value="" disabled selected>กรุณากรอกเลือก</option>
                    <option value="เป็น">เป็น</option>
                    <option value="ไม่เป็น">ไม่เป็น</option>
                    <!--:selected="value && value.includes(role.id)"-->
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="type" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>

              <template v-if="type === 'เป็น'">
                <!--begin::Input group-->
                <div class="row mb-6">
                  <!--begin::Label-->
                  <label class="col-lg-12 col-form-label required fw-bold fs-6"
                    >กลุ่มเปราะบาง (เลือกได้มากว่า 1)</label
                  >
                  <!--end::Label-->
                </div>
                <!--end::Input group-->

                <!--begin::Input group-->
                <div class="row mb-6">
                  <!--begin::Col-->
                  <div class="col-lg-12 fv-row">
                    <Field
                      type="checkbox"
                      name="vulnerable"
                      class="form-check-input"
                      id="vulnerable"
                      value="คนชรา"
                    />
                    <cc class="fw-bold fs-6" style="margin-left: 5px">
                      คนชรา</cc
                    >
                  </div>
                  <!--end::Col-->
                  <!--begin::Col-->
                  <div class="col-lg-12 fv-row">
                    <Field
                      type="checkbox"
                      name="vulnerable"
                      class="form-check-input"
                      id="vulnerable"
                      value="ผู้ป่วยติดเตียง"
                    />
                    <cc class="fw-bold fs-6" style="margin-left: 5px">
                      ผู้ป่วยติดเตียง</cc
                    >
                  </div>
                  <!--end::Col-->
                  <!--begin::Col-->
                  <div class="col-lg-12 fv-row">
                    <Field
                      type="checkbox"
                      name="vulnerable"
                      class="form-check-input"
                      id="vulnerable"
                      value="ผู้พิการ"
                    />
                    <cc class="fw-bold fs-6" style="margin-left: 5px">
                      ผู้พิการ</cc
                    >
                  </div>
                  <!--end::Col-->
                  <!--begin::Col-->
                  <div class="col-lg-12 fv-row">
                    <Field
                      type="checkbox"
                      name="vulnerable"
                      class="form-check-input"
                      id="vulnerable"
                      value="สตรีมีครรภ์"
                    />
                    <cc class="fw-bold fs-6" style="margin-left: 5px">
                      สตรีมีครรภ์</cc
                    >
                  </div>
                   <div class="col-lg-12 fv-row">
                    <Field
                      type="checkbox"
                      name="vulnerable"
                      class="form-check-input"
                      id="vulnerable"
                      value="เด็กแรกเกิด"
                    />
                    <cc class="fw-bold fs-6" style="margin-left: 5px">
                      เด็กแรกเกิด</cc
                    >
                  </div>
                            <div class="col-lg-12 fv-row">
                    <Field
                      type="checkbox"
                      name="vulnerable"
                      class="form-check-input"
                      id="vulnerable"
                      value="ผู้ป่วยเอดส์"
                    />
                    <cc class="fw-bold fs-6" style="margin-left: 5px">
                      ผู้ป่วยเอดส์</cc
                    >
                  </div>
                  <!--end::Col-->
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="vulnerable" />
                    </div>
                  </div>
                </div>
                <!--end::Input group-->
              </template>
            </div>
            <!--end::Card body-->
          </div>
          <div class="card-footer white">
            <div class="d-flex justify-center align-center">
              <div style="width: 100%; padding: 0px 5px">
                <button type="button" @click="back" class="button btn-back">
                  ย้อนกลับ
                </button>
              </div>
              <div style="width: 100%; padding: 0px 5px">
                <button type="submit" style="width: 100%" class="button">
                  เพิ่มสมาชิก
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import iconMale from "@/assets/icon/Male.png";
import iconFemale from "@/assets/icon/Female.png";
import { defineComponent, onMounted, inject, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import useSweetalert from "@/service/sweetalert2";
import useRegisters from "@/service/api/register";
import * as Yup from "yup";
import liff from "@line/liff";
export default defineComponent({
  name: "Register",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const store = inject("store");
    const router = inject("router");
    const register = Yup.object().shape({
      gender: Yup.string().required("กรุณาเลือก เพศ"),
      age: Yup.number().required("กรุณากรอก อายุ"),
      type: Yup.string().required("กรุณาเลือก กลุ่มเปราะบาง"),
      vulnerable: Yup.array().when("type", {
        is: (type) => type === "เป็น",
        then: Yup.array()
          .min(1, "กรุณากรอกเลือกอย่างน้อย 1 ข้อ")
          .required("กรุณากรอกเลือก กลุ่มเปราะบาง"),
      }),
    });

    const { Sconfirm, SconfirmNc } = useSweetalert();
    const { Register } = useRegisters();
    const type = ref("");

    let isLoading = ref(false);
    let color = ref(process.env.VUE_APP_COLOR);

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    const onSubmitRegister = (values) => {
      Sconfirm("ยืนยัน การเพิ่มสมาชิก", "question")
        .then(async (result) => {
          if (result.isConfirmed) {
            isLoading.value = true;
            values.id_line = store.getters.getAccessToken.accesstoken;
            await Register(values).then((result) => {
              isLoading.value = false;
              if (result.data.status === "success") {
                SconfirmNc("เพิ่มสมาชิกสำเร็จ", "success", "ตกลง").then(() => {
                  isLoading.value = false;
                  router.push({ name: "Home" });
                });
              } else {
                SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(
                  () => {
                    liff.ready.then(() => {
                      liff.closeWindow();
                    });
                  }
                );
              }
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            isLoading.value = false;
            SconfirmNc("เกิดข้อผิดผาด กรุณาลองใหม่อีกครั้ง", "error", "ตกลง");
          }
        });
    };

    const back = () => {
      router.push({ name: "Dashboard" });
    };

    return {
      onSubmitRegister,
      register,
      iconMale,
      iconFemale,
      isLoading,
      color,
      back,
      type,
    };
  },
});
</script>

<style scoped>
.bdhd {
  border-bottom: 2px solid #dee2e6 !important;
}
.icon-gender {
  width: 50px;
  padding: 5px;
}
.gender {
  padding: 30px 20px;
  border: 1px solid #dee2e6;
  border-radius: 10px;
}
input[type="radio"] {
  display: none;
}
input:checked + span {
  color: var(--color);
  border: 2px solid var(--color);
}
</style>
