import ApiService from "@/service/ApiService";

export default function useRegister() {
  const Register = async (data) => {
    return await ApiService.post("register_vulnerable", data);
  };

  const getVulnerable = async () => {
    return await ApiService.get("get_vulnerable");
  };

  const getCommunitys = async () => {
    return await ApiService.get("get_communitys");
  };

  const garbageRegister = async (data) => {
    return await ApiService.post("garbage_register", data);
  };

  const getBills = async (id) => {
    return await ApiService.get("get_garbage_bills/" + id);
  };

  const getBill = async (id) => {
    return await ApiService.get("get_garbage_bill/" + id);
  };

  const garbagePay = async (data) => {
    return await ApiService.post("garbage_pay", data);
  };

  const getPay = async (id) => {
    return await ApiService.get("get_garbage_pay/" + id);
  };
  




  

  return {
    Register,
    getVulnerable,
    getCommunitys,
    garbageRegister,
    getBills,
    getBill,
    garbagePay,
    getPay
  };
}
