<template>
  <div class="vld-parent">
    <loading :active="isLoading" :can-cancel="false" :is-full-page="false" loader="dots" :color="color"></loading>
    <div style="padding: 10px 20px" class="mt-2 white">
      <button style="border:1px solid #5558B5; color: #5558B5;" type="button" @click="add" class="btn btn-outline w-100">
        เพิ่มสมาชิกในบ้าน <i class="bi bi-person-plus icon-add"></i>
      </button>
    </div>
    <template v-for="vulnerable in vulnerables" :key="vulnerable">
      <div style="padding: 20px" class="mt-2 white">
        <div class="body-text" v-if="vulnerables.length > 0">
          <div class="p-item">
            <div class="image">
              <img :src="setIcon(vulnerable.gender)" class="appeal-image-logo" alt="" />
            </div>

            <div class="text" style="padding: 5px">
              อายุ : {{ vulnerable.age }}<br />
              <span v-if="vulnerable.elderly" class="ml-2"><span class="fw-bold fs-6 text-root">คนชรา : </span>
                <span @click="goTo('elderly')">ดูสิทธิประโยชน์</span><br /></span>
              <span v-if="vulnerable.bedridden" class="ml-2"><span class="fw-bold fs-6 text-root">ผู้ป่วยติดเตียง :
                </span><span @click="goTo('bedridden')">ดูสิทธิประโยชน์</span><br /></span>
              <span v-if="vulnerable.cripple" class="ml-2"><span class="fw-bold fs-6 text-root">ผู้พิการ : </span><span
                  @click="goTo('cripple')">ดูสิทธิประโยชน์</span><br /></span>
              <span v-if="vulnerable.pregnant" class="ml-2"><span class="fw-bold fs-6 text-root">สตรีมีครรภ์ :
                </span><span @click="goTo('pregnant')">ดูสิทธิประโยชน์</span><br /></span>
              <span v-if="vulnerable.newborn" class="ml-2"><span class="fw-bold fs-6 text-root">เด็กแรกเกิด : </span><span
                  @click="goTo('newborn')">ดูสิทธิประโยชน์</span><br /></span>
              <span v-if="vulnerable.aids" class="ml-2"><span class="fw-bold fs-6 text-root">ผู้ป่วยเอดส์ : </span><span
                  @click="goTo('aids')">ดูสิทธิประโยชน์</span><br /></span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import iconMale from "@/assets/icon/Male.png";
import iconFemale from "@/assets/icon/Female.png";
import { defineComponent, inject, onMounted, ref } from "vue";
import useRegisters from "@/service/api/register";
export default defineComponent({
  name: "Member",
  setup() {
    const router = inject("router");
    const { getVulnerable } = useRegisters();
    const vulnerables = ref([]);

    let isLoading = ref(true);
    let color = ref(process.env.VUE_APP_COLOR);

    const add = () => {
      router.push({ name: "Member" });
    };

    const setIcon = (val) => {
      if (val === "ชาย") {
        return iconMale;
      } else {
        return iconFemale;
      }
    };

    const goTo = (val) => {
      router.push({ name: "Show_member", params: { id: val } });
    };

    onMounted(() => {
      getVulnerable().then((res) => {
        vulnerables.value = res.data.data;
        isLoading.value = false;
      });
    });

    return { add, isLoading, color, vulnerables, setIcon, goTo };
  },
});
</script>
<style scoped>
.icon-add {
  float: none;
  margin-left: 5px;
}

.card-body {
  padding: 0;
}

.title {
  background-color: white;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 15px 10px;
}

.title-text {
  font-size: 12px;
  margin-right: 5px;
}

.icon-logo {
  height: 27px;
  background-color: white;
  margin-left: 10px;
  border-radius: 50%;
  margin-top: -10px;
}

.ml-2 {
  margin-left: 2px;
}

.appeal-title {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 0.125rem;
}

.ml {
  padding: 10px;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.p-item {
  display: flex;
  min-height: 5rem;
  border-bottom: 1.3px solid #dee2e6;
}

.appeal-image {
  position: relative;
}

.appeal-image-logo {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border: 1.3px solid #dee2e6;
  border-radius: 15px;
  padding: 5px;
}
</style>
