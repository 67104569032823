<template>
  <div style="padding: 20px" class="mt-2 white">


    <!--begin::Row-->
    <div class="row mb-2">
      <!--begin::Label-->
      <label class="col-5 fw-bold text-root">บ้านเลขที่</label>
      <!--end::Label-->
      <!--begin::Col-->
      <div class="col-7">
        <span class="fw-bolder fs-6 text-dark">{{ Profire.numberhome }}</span>
      </div>
      <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-2">
      <!--begin::Label-->
      <label class="col-5 fw-bold text-root">หมู่</label>
      <!--end::Label-->
      <!--begin::Col-->
      <div class="col-7">
        <span v-if="Profire.moo" class="fw-bolder fs-6 text-dark">{{
          Profire.moo
        }}</span>
        <span v-else class="fw-bolder fs-6 text-dark">-</span>
      </div>
      <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-2">
      <!--begin::Label-->
      <label class="col-5 fw-bold text-root">ซอย</label>
      <!--end::Label-->
      <!--begin::Col-->
      <div class="col-7">
        <span v-if="Profire.alleyway" class="fw-bolder fs-6 text-dark">{{
          Profire.alleyway
        }}</span>
        <span v-else class="fw-bolder fs-6 text-dark">-</span>
      </div>
      <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-2">
      <!--begin::Label-->
      <label class="col-5 fw-bold text-root">ถนน</label>
      <!--end::Label-->
      <!--begin::Col-->
      <div class="col-7">
        <span v-if="Profire.road" class="fw-bolder fs-6 text-dark">{{
          Profire.road
        }}</span>
        <span v-else class="fw-bolder fs-6 text-dark">-</span>
      </div>
      <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-2">
      <!--begin::Label-->
      <label class="col-5 fw-bold text-root">ตำบล</label>
      <!--end::Label-->
      <!--begin::Col-->
      <div class="col-7">
        <span class="fw-bolder fs-6 text-dark">{{ Profire.district }}</span>
      </div>
      <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-2">
      <!--begin::Label-->
      <label class="col-5 fw-bold text-root">อำเภอ</label>
      <!--end::Label-->
      <!--begin::Col-->
      <div class="col-7">
        <span class="fw-bolder fs-6 text-dark">{{ Profire.amphoe }}</span>
      </div>
      <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-2">
      <!--begin::Label-->
      <label class="col-5 fw-bold text-root">จังหวัด</label>
      <!--end::Label-->
      <!--begin::Col-->
      <div class="col-7">
        <span class="fw-bolder fs-6 text-dark">{{ Profire.province }}</span>
      </div>
      <!--end::Col-->
    </div>
    <!--end::Row-->


    <div class="row mb-2">
      <!--begin::Label-->
      <label class="col-5 fw-bold text-root">ชุมชน</label>
      <!--end::Label-->
      <!--begin::Col-->
      <div class="col-7" v-if="Profire.gabage_fee">
        <span class="fw-bolder fs-6 text-dark">{{ Profire.gabage_fee[0].community }}</span>
      </div>
      <div class="col-7" v-else>
        <span class="fw-bolder fs-6 text-dark">ไม่พบข้อมูล</span>
      </div>
      <!--end::Col-->
    </div>


  </div>
</template>

<script>
import { defineComponent, inject } from "vue";

export default defineComponent({
  name: "Profire",
  setup() {
    const store = inject("store");
    const Profire = store.getters.getProfire;
    return { Profire };
  },
});
</script>
