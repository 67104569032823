<template>
  <div style="padding: 20px" class="mt-2 white">


    <!--begin::Row-->
    <div class="row mb-2">
      <!--begin::Label-->
      <label class="col-5 fw-bold text-root">ชุมชน</label>
      <!--end::Label-->
      <!--begin::Col-->
      <div class="col-7">
        <span class="fw-bolder fs-6 text-dark">{{ garbage.community }}</span>
      </div>
      <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-2">
      <!--begin::Label-->
      <label class="col-5 fw-bold text-root">ประเภท</label>
      <!--end::Label-->
      <!--begin::Col-->
      <div class="col-7">

        <span  class="fw-bolder fs-6 text-dark">{{ garbage.type }}</span>
      </div>
      <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-2" v-if="garbage.type_name">
      <!--begin::Label-->
      <label class="col-5 fw-bold text-root">ชื่อสถานประกอบการ / ชื่อร้าน</label>
      <!--end::Label-->
      <!--begin::Col-->
      <div class="col-7">

        <span  class="fw-bolder fs-6 text-dark">{{garbage.type_name }}</span>
      </div>
      <!--end::Col-->
    </div>
    <!--end::Row-->

    <!--begin::Row-->
    <div class="row mb-2">
      <!--begin::Label-->
      <label class="col-5 fw-bold text-root">สถานะ</label>
      <!--end::Label-->
      <!--begin::Col-->
      <div class="col-7">
        
        <span  class="fw-bolder fs-6 text-dark">{{ garbage.status }}</span>
      </div>
      <!--end::Col-->
    </div>
    <!--end::Row-->



  </div>
</template>

<script>
import { defineComponent, inject } from "vue";

export default defineComponent({
  name: "Profire",
  props: {
    garbage: {
      type: [Array],
    },
  },
  setup() {
    const store = inject("store");
    const Profire = store.getters.getProfire;
    return { Profire };
  },
});
</script>
