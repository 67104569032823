<template>
  <div>
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>
    <div class="col-12">
      <div class="card concard card-shadow">
        <Header></Header>

        <div class="text-start mn-profile">
          <span class="text-root mini-h"> ชำระค่าธรรมเนียมขยะ </span>
        </div>
        <div class="card-body" style="position: relative">
          <div class="card-body mb-47 mt-2 white">
            <!--begin::Card body-->
            <div class="card-body">
              <!--begin::Input group-->
              <div class="row" style="padding: 10px">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6"
                  >เดือน</label
                >
                <!--end::Label-->

                <div class="col-lg-8 fv-row">
                  {{ month(data_bill.month) }} {{ year(data_bill.year) }}
                </div>

                <!--end::Col-->
              </div>
              <span class="orange" v-if="data_bill.status === 'รอตรวจสอบ'">{{ data_bill.status }} </span>
              <span class="green" v-if="data_bill.status === 'เสร็จสิ้น'">{{ data_bill.status }} </span>
              <!--begin::Input group-->
              <div class="row" style="padding: 10px">
                <!--begin::Label-->
                <label class="col-6 col-form-label fw-bold fs-6">ยอดชำระ</label>
                <label class="col-6 col-form-label fw-bold fs-6"
                  >ยอดที่จ่าย</label
                >
                <!--end::Label-->
              </div>
              <!--begin::Col-->
              <div class="row" style="padding: 10px">
                <div class="col-6 fv-row">{{ data_bill.payout }} บาท</div>
                <div class="col-6 fv-row">{{ pay.payout }} บาท</div>
                <!--end::Col-->
              </div>

              <div class="row" style="padding: 10px">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6"
                  >วันที่โอน</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  {{ dateThai(pay.day_pay) }}
                </div>
                <!--end::Col-->
              </div>

              <div class="row" style="padding: 10px">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6"
                  >เวลาที่โอน</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  {{ pay.time_pay }}
                </div>
                <!--end::Col-->
              </div>

              <!--begin::Input group-->
              <div class="row mb-3" style="padding: 10px">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6"
                  >สลิปการโอนเงิน</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <div
                    v-if="pay.image"
                    class="imagePreviewWrapper"
                    style="padding: 15px 0px"
                  >
                    <div class="row">
                      <div style="position: relative" class="col-12">
                        <img class="images" :src="pay.image" />
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
            </div>
            <!--end::Card body-->
          </div>
          <div class="card-footer white">
            <div class="d-flex justify-center align-center">
              <div style="width: 100%; padding: 0px 5px">
                <button type="button" @click="back" class="button btn-back">
                  ย้อนกลับ
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, inject, ref } from "vue";
import moment from "moment";
import useRegisters from "@/service/api/register";
export default defineComponent({
  name: "Profire",
  props: {
    bill: {
      type: [Object],
    },
  },
  setup(props) {
    const store = inject("store");
    const router = inject("router");
    const pay = ref("");

    let isLoading = ref(true);
    let color = ref(process.env.VUE_APP_COLOR);

    const data_bill = ref(props.bill);

    const { getPay } = useRegisters();

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }

      getPay(data_bill.value.id).then((res) => {
        pay.value = res.data.data;
        isLoading.value = false;
      });
    });

    function month(date) {
      var march = moment().month(date - 1).locale("th");

      return march.format("MMMM");
    }

    function year(date) {
      var year = parseInt(date) + 543;

      return year;
    }

    function dateThai(date) {
      var y = moment(date).format("yyy");
      var year = parseInt(y) + 543;
      var march = moment(date).locale("th");

      return march.format("D MMM" + year);
    }

    const back = () => {
      router.push({ name: "Garbage_Home" });
    };

    return {
      month,
      year,
      dateThai,
      back,
      pay,
      isLoading,
      color,
      data_bill,
    };
  },
});
</script>
<style scoped>
.card-body {
  padding: 0;
}
.rr {
  position: absolute;
  right: 20px;
  color: red;
  font-size: 18px;
  top: 10px;
}
.wait {
  color: orange;
}
.orange {
  position: absolute;
  right: 20px;
  color: orange;
  font-size: 18px;
  top: 10px;
}
.green {
  position: absolute;
  right: 20px;
  color: green;
  font-size: 18px;
  top: 10px;
}
.title {
  background-color: white;
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  padding: 15px 10px;
}
.title-text {
  font-size: 12px;
  margin-right: 5px;
}
.icon-logo {
  height: 27px;
  background-color: white;
  margin-left: 10px;
  border-radius: 50%;
  margin-top: -10px;
}
.body-text {
  position: relative;
}
.hide-scrollbar {
  position: relative;
  height: 100%;
  overflow-y: hidden;
  scrollbar-width: none;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}
.scrollbar {
  height: 0.25rem;
  border-radius: 0.0625rem;
  position: absolute;
  bottom: 0.3125rem;
  left: calc(50% - 0.75rem);
  width: 1.5rem;
  background: var(--color);
}
.scrollbar-st {
  background: #ee4d2d;
  width: 10.801px;
  transform: translateX(0px);
}
.appeal-title {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 0.125rem;
}

.appeal-item {
  width: 33.33%;
  padding: 0 0.0625rem;
  text-align: center;
  height: 7rem;
}
.appeal-image {
  position: relative;
}
.appeal-image-logo {
  vertical-align: bottom;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border: 1.3px solid #dee2e6;
  border-radius: 15px;
}
.history-image {
  position: relative;
}
.history-image-logo {
  vertical-align: bottom;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
  border: 1.3px solid #dee2e6;
  border-radius: 15px;
}
.appeal-text {
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  max-height: 2.625rem;
  font-size: 12px;
  line-height: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  word-wrap: break-word;
  overflow: hidden;
  white-space: normal;
  height: 30px;
}
.history-header {
  padding: 10px;
  display: flex;
}
.history-title {
  width: 50%;
  margin-left: 5px;
  font-size: 13px;
  font-weight: 500;
}
.history-status {
  width: 50%;
  font-size: 12px;
  text-align: end;
}
.history-body {
  padding: 10px;
  display: flex;
  position: relative;
}
.history-detail {
  margin-left: 10px;
}
.history-footer {
  font-size: 12px;
  padding: 10px;
  margin-left: 5px;
}
.history-botton {
  position: absolute;
  right: 10px;
  bottom: 0;
}
.see-more {
  background-color: var(--color) !important;
  border-color: var(--color) !important;
  color: white;
}
.history-empty {
  position: relative;
  height: 157.5px;
}
.history-empty-text {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px 0 0 -40px;
  opacity: 0.8;
}

.images {
  width: 100%;
}
.xx {
  position: absolute;
  background-color: #bd313e;
  color: white;
  right: 12px;
  padding: 6px;
}
</style>
