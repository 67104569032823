import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import CKEditor from '@ckeditor/ckeditor5-vue';
import ApiService from "@/service/ApiService";

import Header from "@/components/Header";
import Tabs from "@/components/Tabs";
import Tab from "@/components/Tab";
import TabPanels from "@/components/TabPanels";
import TabPanel from "@/components/TabPanel";


import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";

const app = createApp(App);
ApiService.init(app);

app.use(store);
app.use(router);
app.use(CKEditor);



app.component('Header', Header); 
app.component('Tabs', Tabs); 
app.component('Tab', Tab); 
app.component('TabPanels', TabPanels); 
app.component('TabPanel', TabPanel); 

app.component("Loading", Loading);

app.provide("store", store);
app.provide("router", router);
app.mount("#app");
