<template>
  <div class="bg-home vh100">
    <div
      style="
        width: 200px;
        height: 60px;
        position: absolute;
        left: 50%;
        top: 17%;
        transform: translate(-50%, -50%);
        text-align: center;
      "
    >
      <img
        src="@/assets/logo.png"
        style="width: 65%; border-radius: 50%; background-color: aliceblue"
        alt="..."
      />
    </div>
    <div class="wrapper">
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="circle"></div>
      <div class="shadow"></div>
      <div class="shadow"></div>
      <div class="shadow"></div>
      <span style="color: white">กรุณารอสักครู่</span>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import { defineComponent, onMounted, inject, ref } from "vue";
import useLogin from "@/service/api/login";
import useSweetalert from "@/service/sweetalert2";
import { SET_GARBAGE } from "@/store/modules/ProfireLine";
export default defineComponent({
  name: "Garbage",
  setup() {
    const store = inject("store");
    const router = inject("router");
    const { CheckGarbage } = useLogin();
    const { SconfirmNc } = useSweetalert();

    let isLoading = ref(true);
    let color = ref(process.env.VUE_APP_COLOR);
    const garbage = ref([])

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }

      CheckGarbage(store.getters.getAccessToken.accesstoken).then(
        (response) => {
          isLoading.value = false;

          if (response.data.status === "Success") {
            store.dispatch(SET_GARBAGE, response.data.data);
            router.push({ name: "Garbage_Home" });
          } else if (response.data.status === "Not Member") {
            router.push({ name: "Register" });
          } else {
            SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง");
          }
        }
      );
    });

    return {
      garbage,
      isLoading,
      color,
    };
  },
});
</script>

<style scoped>
.bg-home {
  background-color: var(--color);
}
.wrapper {
  width: 200px;
  height: 60px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  background-color: white;
  left: 15%;
  transform-origin: 50%;
  animation: circle 0.5s alternate infinite ease;
}

@keyframes circle {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }
  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }
  100% {
    top: 0%;
  }
}
.circle:nth-child(2) {
  left: 45%;
  animation-delay: 0.2s;
}
.circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
.shadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow 0.5s alternate infinite ease;
}

@keyframes shadow {
  0% {
    transform: scaleX(1.5);
  }
  40% {
    transform: scaleX(1);
    opacity: 0.7;
  }
  100% {
    transform: scaleX(0.2);
    opacity: 0.4;
  }
}
.shadow:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}
.shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
.wrapper span {
  position: absolute;
  top: 75px;
  font-size: 21px;
  letter-spacing: 5px;
  color: white;
  left: 10%;
  font-weight: 500;
}
</style>
