<template>
  <div class="row">
    <loading
      v-model:active="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :color="color"
      loader="dots"
    />
    <div class="col-12 f13">
      <div class="card concard card-shadow">
        <div class="card-header white text-center">
          <img src="@/assets/logo.png" style="height: 100px" alt="..." />
          <div class="t1" style="margin: 5px">ข้อมูลที่อยู่</div>
        </div>
        <Form
          @submit="onSubmitRegister"
          :validation-schema="register"
          id="kt_account_profile_details_form"
          ref="formContainer"
          novalidate="novalidate"
        >
          <div class="card-body mb-47 mt-2 white">
            <!--begin::Card body-->
            <div class="card-body">
              <!--begin::Input group-->
                      <!--begin::Input group-->
              <div class="row mb-3" style="padding:10px">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >ชุมชน</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    name="community"
                    class="form-control form-control-md form-control-solid"
                    as="select"
                  >
                    <option value="" selected disabled>โปรดเลือก</option>
                    <option
                      v-for="community in communitys"
                      :key="community"
                      :value="community.name"
                    >
                      {{ community.name }}
                    </option>
                    <!--:selected="value && value.includes(role.id)"-->
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="community" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

                                    <!--begin::Input group-->
              <div class="row mb-3" style="padding:10px">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >ประเภท</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    name="type"
                    class="form-control form-control-md form-control-solid"
                    as="select"
                    v-model="type"
                  >
                    <option value="" selected disabled>โปรดเลือก</option>
                    <option value="บ้านพักอาศัย">บ้านพักอาศัย</option>
                    <option value="สถานประกอบการ">สถานประกอบการ</option>
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="type" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->

              <template v-if="type === 'สถานประกอบการ'">
                       <!--begin::Input group-->
              <div class="row mb-3" style="padding:10px">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label required fw-bold fs-6"
                  >ชื่อสถานประกอบการ / ชื่อร้าน</label
                >
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-8 fv-row">
                  <Field
                    type="text"
                    name="type_name"
                    class="form-control form-control-solid"
                    placeholder="ชื่อสถานประกอบการ / ชื่อร้าน"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="type_name" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
              <!--end::Input group-->
              </template>

              <div class="row mb-3">
                   <!--begin::Label-->
                <label style="padding: 10px 20px;" class="col-lg-4 col-form-label required fw-bold fs-6"
                  >พิกัดที่อยู่บ้าน</label
                >
                <!--end::Label-->
                <div id="mapid" style="height: 300px"></div>
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="latitude" />
                  </div>
                </div>
                <Field type="hidden" name="latitude" v-model="latitude" />
                <Field type="hidden" name="longitude" v-model="longitude" />
                <div>
                  <div class="myl" @click="mylo">
                    ตำแหน่งของฉัน<i
                      class="bi bi-geo-fill"
                      style="margin-left: 10px"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Card body-->
          </div>
          <div class="card-footer white">
            <div class="d-flex justify-center align-center">
              <div style="width: 100%; padding: 0px 5px">
                <button type="button" @click="back" class="button btn-back">
                  ย้อนกลับ
                </button>
              </div>
              <div style="width: 100%; padding: 0px 5px">
                <button type="submit" style="width: 100%" class="button">
                  ลงทะเบียน
                </button>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, inject, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import useSweetalert from "@/service/sweetalert2";
import useRegisters from "@/service/api/register";
import * as Yup from "yup";
import liff from "@line/liff";
import leaflet from "leaflet";
export default defineComponent({
  name: "Register",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    let mymap;
    const store = inject("store");
    const router = inject("router");
    const register = Yup.object().shape({
      community:Yup.string().required("กรุณาเลือกชุมชน"),
      type:Yup.string().required("กรุณาเลือกประเภท"),
      type_name: Yup.string().when("type", {
        is: (type) => type === "สถานประกอบการ",
        then: Yup.string()
          .required("กรุณากรอก ชื่อสถานประกอบการ / ชื่อร้าน"),
      }),
      latitude: Yup.string().required("กรุณาปักหมุดพิกัดบ้าน"),
    });

    let latitude = ref("");
    let longitude = ref("");
    let marker = ref([]);
    const communitys = ref([]);

    const type = ref('');

    const { Sconfirm, SconfirmNc } = useSweetalert();
    const { garbageRegister , getCommunitys } = useRegisters();

    let isLoading = ref(true);
    let color = ref(process.env.VUE_APP_COLOR);

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
      setLocation();
      getCommunitys()
      .then((res) => {
           communitys.value = res.data.data
           isLoading.value = false;
      })

      mymap = leaflet
        .map("mapid")
        .setView([process.env.VUE_APP_LAT, process.env.VUE_APP_LNG], 15);
      leaflet
        .tileLayer(
          "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYmFjb3MyNSIsImEiOiJja3Y2a2pyNmk5YWZxMzBxanVrNno3NnE0In0.okOhpHHfbDXptqipC8iWTg",
          {
            attribution: "bacos25",
            maxZoom: 18,
            id: "mapbox/streets-v11",
            accessToken:
              "pk.eyJ1IjoiYmFjb3MyNSIsImEiOiJja3Y2a2pyNmk5YWZxMzBxanVrNno3NnE0In0.okOhpHHfbDXptqipC8iWTg",
          }
        )
        .addTo(mymap);
      marker.value = leaflet.marker(
        [process.env.VUE_APP_LAT, process.env.VUE_APP_LNG],
        {
          draggable: true,
        }
      );

      marker.value.on("dragend", function (event) {
        latitude.value = event.target._latlng.lat;
        longitude.value = event.target._latlng.lng;
      });

      marker.value.addTo(mymap);
    });

    function setLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          latitude.value = position.coords.latitude;
          longitude.value = position.coords.longitude;
          let newLatLng = new leaflet.LatLng(
            position.coords.latitude,
            position.coords.longitude
          );
          marker.value.setLatLng(newLatLng);
          mymap.panTo(newLatLng);
        });
      } else {
        alert("Geolocation is not supported by your browser");
      }
    }

    const mylo = () => {
      navigator.geolocation.getCurrentPosition((position) => {
        let newLatLng = new leaflet.LatLng(
          position.coords.latitude,
          position.coords.longitude
        );
        marker.value.setLatLng(newLatLng);
        mymap.panTo(newLatLng);
      });
    };

    const onSubmitRegister = (values) => {
      Sconfirm("ยืนยัน การลงทะเบียน", "question")
        .then(async (result) => {
          if (result.isConfirmed) {
            isLoading.value = true;
            values.id_line = store.getters.getAccessToken.accesstoken;
            await garbageRegister(values).then((result) => {
              isLoading.value = false;
              if (result.data.status === "success") {
                SconfirmNc("ลงทะเบียนสำเร็จ", "success", "ตกลง").then(() => {
                  isLoading.value = false;
                  router.push({ name: "Garbage" });
                });
              } else {
                SconfirmNc("ไม่มีสิทธิ์เข้าใช้งาน", "error", "ตกลง").then(
                  () => {
                    liff.ready.then(() => {
                      liff.closeWindow();
                    });
                  }
                );
              }
            });
          }
        })
        .catch((error) => {
          if (error.response) {
            isLoading.value = false;
            SconfirmNc("เกิดข้อผิดผาด กรุณาลองใหม่อีกครั้ง", "error", "ตกลง");
          }
        });
    };

    const back = () => {
      router.push({ name: "Dashboard" });
    };

    return {
      onSubmitRegister,
      register,
      isLoading,
      color,
      back,
       mylo,
      latitude,
      longitude,
      communitys,
      type
    };
  },
});
</script>

<style scoped>
.card-body {
  padding: 0;
}
.myl {
  line-height: 40px;
  text-align: center;
  border: 1px solid #ccc;
  font-size: 17px;
}
@import "https://unpkg.com/leaflet@1.7.1/dist/leaflet.css";
</style>
