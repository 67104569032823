<template>
  <div style="padding: 20px" class="mt-3 white">
    <router-link :to="{
      name: 'Garbage',
    }">
      <button type="button" style="width: 100%;background-color: #5558B5;" class="button btn btn-primary">
        ตรวจสอบค่าธรรมเนียมขยะ
      </button>
    </router-link>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Garbage",
});
</script>
