import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/Home.vue";
import Dashboard from "@/views/dashboard/Dashboard.vue";
import Add_member from "@/views/Member/Add.vue";
import Show_member from "@/views/Member/Show.vue";
import Register from "@/views/Register/Index.vue";
import Garbage from "@/views/Garbage/Home.vue";
import Garbage_Home from "@/views/Garbage/Index.vue";
import Garbage_Show from "@/views/Garbage/Show.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/member",
    name: "Member",
    component: Add_member,
  },
  {
    path: "/member/:id",
    name: "Show_member",
    component: Show_member,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/garbage",
    name: "Garbage",
    component: Garbage,
  },
  {
    path: "/garbage/home",
    name: "Garbage_Home",
    component: Garbage_Home,
  },
  {
    path: "/garbage/home/:id",
    name: "Garbage_Show",
    component: Garbage_Show,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
