<template>
  <div class="row">
    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      loader="dots"
      :color="color"
    ></loading>

    <Pay :bill="bill" v-if="bill.status ==='ค้างชำระ'"></Pay>
    <Show :bill="bill" v-if="bill.status && bill.status !=='ค้างชำระ'"></Show>
  </div>
</template>

<script>
import Pay from "@/components/Garbage/show/Pay.vue";
import Show from "@/components/Garbage/show/Show.vue";
import { defineComponent, onMounted, inject, ref } from "vue";
import useRegisters from "@/service/api/register";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "Garbage_Home",
  components: {
    Pay,
    Show
  },
  setup() {
    const store = inject("store");
    const router = inject("router");
    const { getBill } = useRegisters();
    const bill = ref("");

    const garbage = ref(store.getters.getGarbage);
    const route = useRoute();
    const id = ref(route.params.id);

    let isLoading = ref(true);
    let color = ref(process.env.VUE_APP_COLOR);

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }

      getBill(id.value).then((res) => {
        bill.value = res.data.data;
        isLoading.value = false;
      });
    });

  

    return {
      garbage,
      bill,
      isLoading,
      color,
      id
    };
  },
});
</script>

<style scoped></style>
