<template>
  <div class="row">
    <div class="col-12 f13">
      <div class="card concard card-shadow">
        
          <img :src="setImage()" style="width: 100%" alt="" />
        
        <div class="card-footer white">
          <div class="d-flex justify-center align-center">
            <div style="width: 100%; padding: 0px 5px">
              <button type="button" @click="back" class="button btn-back">
                ย้อนกลับ
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import iconElderly from "@/assets/image/elderly.jpg";
import iconCripple from "@/assets/image/cripple.jpg";
import iconNewborn from "@/assets/image/newborn.jpg";
import iconAids from "@/assets/image/aids.jpg";
import { defineComponent, onMounted, inject, ref } from "vue";
import { useRoute } from "vue-router";
export default defineComponent({
  name: "sh",
  setup() {
    const store = inject("store");
    const router = inject("router");

    const route = useRoute();
    const id = ref(route.params.id);

    onMounted(() => {
      if (!store.getters.getAccessToken.accesstoken) {
        router.push({ name: "Home" });
      }
    });

    function setImage() {
      let image;
      if (id.value === "elderly") {
        image = iconElderly;
      }
      if (id.value === "cripple") {
        image = iconCripple;
      }
      if (id.value === "newborn") {
        image = iconNewborn;
      }
      if (id.value === "aids") {
        image = iconAids;
      }

      return image;
    }

    const back = () => {
      router.push({ name: "Dashboard" });
    };

    return {
      back,
      setImage,
    };
  },
});
</script>

<style scoped></style>
